.Tuner {
  width: 300px;
  height: 200px;
  background: #44f;
  position: relative;
  border-radius: 5px;

  .window {
    width: 50%;
    height: 40%;
    position: absolute;
    top: 10%;
    left: 8%;
    background: silver;
    border-radius: 5px;
  }

  .pitch-display {
    width: 27.5%;
    height: 40%;
    position: absolute;
    top: 10%;
    right: 8%;
    background: silver;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;

    .pitch {
      margin-bottom: 0;
    }
  }

  .frequency-display {
    width: 50%;
    height: 30%;
    position: absolute;
    bottom: 10%;
    left: 8%;
    background: silver;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;

    .frequency {
      margin-bottom: 0;
    }
  }

  .gauge {
    width: 100%;
    height: 200%;
    background: white;
    border-radius: 100%;
    clip-path: polygon(0% 50%, 0% 0%, 100% 0%, 100% 50%);
  }

  .needle {
    width: 2%;
    height: 40%;
    background: black;
    margin: 0 auto;
    transform-origin: 50% 100%;
    transform: translateY(25%);
    clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
  }

  .power-area {
    width: 27.5%;
    height: 30%;
    position: absolute;
    bottom: 10%;
    right: 8%;
    border-radius: 5px;
    display: flex;
    justify-content: space-around;
    align-items: center;

    .power-button {
      width: auto;
      height: auto;
      font-size: 25px;
      margin: 0;
      padding: 0;

      svg {
        width: 25px;
        height: 25px;
      }
    }

    .light {
      width: 25px;
      height: 25px;
      background: red;
      border-radius: 100%;

      &.on {
        background: red;
      }

      &.off {
        background: gray;
      }
    }
  }

  @media screen and (min-width: 768px) {
    width: 600px;
    height: 400px;

    .power-area {
      .power-button,
      .power-button svg,
      .light {
        width: 50px;
        height: 50px;
      }
    }

    .pitch-display .pitch,
    .frequency-display .frequency {
      font-size: 50px;
    }
  }
}
