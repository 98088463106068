.MetronomePage {
  text-align: center;
  flex: 1;

  .form {
    width: 100%;
    margin: 0 auto;

    .bpm {
      display: flex;
      margin-bottom: 1em;

      .slider {
        flex: 1;
      }

      .number {
        margin-right: 0 !important;
      }
    }
  }

  @media screen and (min-width: 768px) {
    .form {
      width: 50%;
    }
  }
}
