.Metronome {
  width: 100%;
  max-width: 100%;
  margin: 0 auto;

  .container {
    width: 150px;
    height: 250px;
    margin: 0 auto;
    margin-bottom: 3em;
    position: relative;
  }

  .body {
    width: 100%;
    height: 100%;
    background: brown;
    clip-path: polygon(30% 0%, 70% 0%, 100% 100%, 0% 100%);
    position: absolute;
    left: 0;
    top: 0;
  }

  .cutout {
    width: 100%;
    height: 100%;
    background: maroon;
    clip-path: polygon(30% 0%, 70% 0%, 100% 100%, 0% 100%);
    position: absolute;
    left: 0;
    top: 0;
    transform: scale(0.75);
  }

  .stick {
    width: 5%;
    height: 60%;
    margin: 0 auto;
    background: silver;
    transform-origin: 50% 100%;
    position: absolute;
    left: 50%;
    bottom: 25%;

    .weight {
      width: 200%;
      height: 5%;
      background: silver;
      transform: translateX(-25%);
    }
  }

  .cover {
    width: 100%;
    height: 30%;
    background: brown;
    transform: scale(0.75);
    position: absolute;
    left: 0;
    bottom: 0;
  }

  @media screen and (min-width: 768px) {
    max-width: 375px;

    .container {
      width: 225px;
      height: 375px;
    }
  }
}
