.Dashboard {
  min-height: 100vh !important;

  .header {
    padding: 0 24px;
    background: white;
    z-index: 100;
    display: flex;
    align-items: center;

    .title {
      margin-bottom: 0;
      padding-left: 16px;
    }
  }

  .content {
    padding: 24px;
    margin: 0;
    min-height: 280px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .sider {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 200px;
    z-index: 50;

    .spacer {
      padding: 0;
    }
  }

  .footer {
    display: flex;
    justify-content: flex-end;
    padding: 24px;
  }

  @media screen and (min-width: 768px) {
    .sider {
      position: relative;
    }

    .footer {
      padding: 24px 50px;
    }
  }
}
